// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-certify-my-herd-js": () => import("./../../../src/pages/certify-my-herd.js" /* webpackChunkName: "component---src-pages-certify-my-herd-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-the-science-js": () => import("./../../../src/pages/the-science.js" /* webpackChunkName: "component---src-pages-the-science-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-details-js": () => import("./../../../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */)
}

