import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import * as React from "react"

export const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { alt, filename, classes } = props
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
      })
      if (!image) {
        return null
      }
      return (
        <Img
          style={{ zIndex: 0 }}
          alt={alt}
          fluid={image.node.childImageSharp.fluid}
          className={classes}
        />
      )
    }}
  />
)

Image.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
  classes: PropTypes.string,
}

export default Image
