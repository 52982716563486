import React from "react";
import classes from "./Hamburger.module.css";

const Hamburger = (props) => {
  const {active} = props
  const activeClass = active ? classes.isActive : null;

  return(
    <button className={[classes.hamburger, classes.hamburgerSqueeze, activeClass].join(" ")} type="button">
          <span className={classes.hamburgerBox}>
            <span className={classes.hamburgerInner} />
          </span>
    </button>
  )
}

export default Hamburger;
