import React from "react";
import { useState, useEffect } from 'react';
import classes from './Header.module.css';
import Image from '../Image/image';
import Hamburger from '../Hamburger/Hamburger';
import { Link } from 'gatsby';
import { FaShoppingCart } from "react-icons/fa";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [scrollMenu, setScrollMenu] = useState(false);
  const [modalClasses, setModalClasses] = useState([classes.modal, classes.out]);

  const toggleMenuHandler = () => {
    setIsActive(prevState => !prevState);
    if (!isActive) {
      setModalClasses([classes.modal, classes.in])
    } else {
      setModalClasses([classes.modal, classes.out])
    }
  };

  const handleScroll = () => {
    if (window.scrollY >= 100) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div className={[classes.headerRoot, scrollMenu ? classes.headerBlack : null].join(" ")}>
      <div className={modalClasses.join(" ")}>
        <div className={classes.menu}>
          <Link to="/certify-my-herd" activeClassName={classes.active} onClick={toggleMenuHandler}>CERTIFY MY HERD</Link>
          <Link to="/" activeClassName={classes.active} onClick={toggleMenuHandler}>HOME</Link>
          <Link to="/the-science" activeClassName={classes.active} onClick={toggleMenuHandler}>THE SCIENCE</Link>
          <Link to="/contact" activeClassName={classes.active} onClick={toggleMenuHandler}>CONTACT</Link>
          {/* <Link to="/blogs" activeClassName={classes.active} onClick={toggleMenuHandler}>BLOGS</Link> */}
        </div>
      </div>
      <div className={classes.hamburgerDiv} onClick={toggleMenuHandler}>
        <Hamburger active={isActive} />
      </div>
      <div className={classes.logoDiv}>
        <Image filename="cow-optimized.png" classes={classes.logo} />
      </div>
      <div className={classes.desktopDiv}>
        <Link to="/certify-my-herd" activeClassName={classes.active}>CERTIFY MY HERD</Link>
        <Link to="/" activeClassName={classes.active}>HOME</Link>
        <Link to="/the-science" activeClassName={classes.active}>THE SCIENCE</Link>
        <Link to="/contact" activeClassName={classes.active}>CONTACT</Link>
        {/* <Link to="/blogs" activeClassName={classes.active}>BLOGS</Link> */}
      </div>
      <div className={classes.cartDiv}>
        <div className="header-cart">
          <span className="Header__summary snipcart-summary snipcart-checkout">
            <FaShoppingCart className={classes.shoppingCart} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
