import * as React from "react"
import { FaRegEnvelope } from "react-icons/fa"
import classes from "./Footer.module.css"

const Footer = () => {
  return (
    <footer className={classes.footerSection}>
      <a
        href="mailto:corey@tenetbeef.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaRegEnvelope className={classes.envelope} />
      </a>
      <p className={classes.footerText}>
        Tenet™ Beef is a trademark of{" "}
        <a
          className={classes.anchorTag}
          href="https://www.callipygegenetics.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Callipyge Genetic, LLC
        </a>
      </p>
    </footer>
  )
}

export default Footer
